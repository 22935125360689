import { Controller } from "@hotwired/stimulus";
import Cleave from "cleave.js";

export default class extends Controller {
  static targets = ["formattedPrice"];

  connect() {
    this.initializeCleave();
  }

  initializeCleave() {
    this.formattedPriceTargets.forEach((input) => {
      new Cleave(input, {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalMark: '.',
        delimiter: ',',
        prefix: '$'
      });
    });
  }
}
