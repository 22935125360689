import React, { useState, useEffect } from "react";
import useRegistrationFlowHelpers from "../hooks/useRegistrationFlowHelpers";

import CamperInfoForm from "./camper_info_form";
import MedicalForm from "./medical_form";
import SportForm from "./sport_form";
import WaitlistModal from "./waitlist_modal";
import LiabilityWaiverForm from "./liability_waiver_form";
import WaitlistPaymentForm from "./waitlist_payment_form";
import BballForm from "./bball_form";
import ExciteInfoForm from "./excite_info_form";
import ExciteMedicalForm from "./excite_medical_form";
import SanDiegoStateMedicalForm from "./san_diego_state_medical_form";
import TexasStateSportForm from "./texas_state_sport_form";
import JmuCamperInfoForm from "./jmu_camper_info_form";
import SportsPerformanceForm from "./sports_performance_form";
import CovidInfoForm from "./covid_info_form";

import UtepCamperInfoForm from "./utep_camper_info_form";
import UtepMedicalForm from "./utep_medical_form";
import UtepTransportAuthorizationForm from "./utep_transport_authorization_form";
import UtepSportForm from "./utep_sport_form";
import UtepLiabilityWaiverForm from "./utep_liability_waiver_form";


const generateCamperIdentifier = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const initialCamperTemplate = {
  first_name: "",
  last_name: "",
  birth_date: "",
  player_phone_number: "",
  email: "",
  medications: "",
  conditions: "",
  allergies: "",
  diabetes: null,
  asthma: null,
  sickle_cell_positive: false,
  glasses_or_contacts: false,
  special_needs: false,
  physician: "",
  physician_contact_number: "",
  physician_city: "",
  insurer: "",
  insurance_policy_holder: "",
  group_number: "",
  emergency_contact: "",
  emergency_contact_number: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  height: "",
  weight: "",
  position: "",
  gpa: "",
  sat: "",
  act_score: "",
  club_team: "",
  high_school: "",
  roommate_preference: "",
  peers_attending: "",
  shirt_size: "",
  graduation_year: "",
  liability_waiver: null,
  liability_waiver_initials: "",
  liability_waiver_signature: "",
  guardian_contact: "",
  guardian_contact_number: "",
  guardian_contact_relationship: "",
  guardian_contact_email: "",
  dietary_restrictions: "",
  physical_restrictions: "",
  notes: "",
  injury_history: "",
  training_experience: "",
  goals: "",
  sports: "",
  years_in_sport: "",
  years_of_training: "",
  vaccinated: false,
  vaccine_type: "Not Collected",
  vaccine_dose_one_date: null,
  vaccine_dose_two_date: null,
  positive_test_date: null,
  covid_positive: false,
  covid_cleared: false,
  custom_data: {},
};

const WaitlistRegistrationSteps = (props) => {
  const [step, setStep] = useState(1);
  const [camperTemplate, setCamperTemplate] = useState(initialCamperTemplate);
  const [camperIdentifier, setCamperIdentifier] = useState(generateCamperIdentifier());
  const [campers, setCampers] = useState({});
  const nextStep = () => setStep(step + 1);
  const previousStep = () => setStep(step - 1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    campFull: campFull,
    setFullPricePointFlag,
    createFullPricePointArray,
    createFullPricePointPositionArray,
    createOpenPricePointArray,
  } = useRegistrationFlowHelpers({
    price_points: JSON.parse(props.public_price_points)
  });

  const initializeValues = (data) => {
    const camperInfoWithNewValues = { ...camperTemplate, ...data };
    setCampers({ ...campers, [camperIdentifier]: camperInfoWithNewValues });
  };

  const saveValues = (data) => {
    const updatedCamperInfo = { ...campers[camperIdentifier], ...data };
    setCampers({ ...campers, [camperIdentifier]: updatedCamperInfo });
  };

  const setDetailForm = (event_type, sport, shirt_size) => {
    return (
      <SportForm
        buttonStyles={props.button_styles}
        event_type={props.event_type}
        gender={props.gender}
        collect_tee_shirt_size={props.collect_tee_shirt_size}
        saveValues={saveValues}
        previousStep={previousStep}
        nextStep={nextStep}
        camper_identifier={camperIdentifier}
        campers={campers}
        sport={sport}
        price_points={props.public_price_points}
        full_position_price_points={createFullPricePointPositionArray()}
      />
    );
  };

  const setCamperId = (data) => {
    setCamperIdentifier(data.id);
  };

  const renderRegistrationFlow = () => {
    if (props.custom_registration_flow) {
      switch (props.custom_registration_type) {
        case "excite":
          return exciteRegistrationFlow();
        case "utep":
          return utepRegistrationFlow();
        case "sdsu":
          return sanDiegoStateRegistrationFlow();
        case "texas-state":
          return texasStateRegistrationFlow();
        case "wake-forest":
          return wakeForestRegistrationFlow();
        case "sports-performance":
          return sportsPerformanceRegistrationFlow();
        case "customized-data-collection":
          return customizedDataCollectionFlow();
        case "jmu-purple":
          return jmuRegistrationFlow();
        default:
          return traditionalRegistration();
      }
    } else {
      return traditionalRegistration();
    }
  };
  const traditionalRegistration = () => {
    switch (step) {
      case 1:
        return (
          <div className="event-reg camper-info-form">
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className="event-reg medical-form">
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className="event-reg soccer-form">
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className="event-reg payment-form">
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              date_range={props.date_range}
              csrfToken={document
                .querySelector("meta[name=csrf-token]")
                .getAttribute("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
              waitlist_invitation={props.waitlist_invitation}
              waitlist_entry_id={props.waitlist_entry_id}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const jmuRegistrationFlow = () => {
    switch (step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <JmuCamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camper_identifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              waitlist_entry_id={props.waitlist_entry_id}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };

  const exciteRegistrationFlow = () => {
    switch (step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <ExciteInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg liability-form"}>
            <ExciteMedicalForm
              stepLabel={step}
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              saveValues={saveValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg liability-form"}>
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 4:
        return (
          <div className={"event-reg payment-form"}>
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
              waitlist_invitation={props.waitlist_invitation}
              waitlist_entry_id={props.waitlist_entry_id}
            />
          </div>
        );
    }
  };

  const sanDiegoStateRegistrationFlow = () => {
    switch (step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <SanDiegoStateMedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className={"event-reg liability-form"}>
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
              waitlist_invitation={props.waitlist_invitation}
            />
          </div>
        );
    }
  };
  const sportsPerformanceRegistrationFlow = () => {
    switch (step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            <SportsPerformanceForm
              buttonStyles={props.button_styles}
              event_type={props.event_type}
              custom_registration_type={props.custom_registration_type}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              sport={props.sport}
              price_points={props.public_price_points}
              full_position_price_points={createFullPricePointPositionArray()}
            />
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
              waitlist_invitation={props.waitlist_invitation}
              waitlist_entry_id={props.waitlist_entry_id}
            />
          </div>
        );
    }
  };
  const texasStateRegistrationFlow = () => {
    switch (step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            <TexasStateSportForm
              buttonStyles={props.button_styles}
              event_type={props.event_type}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={props.campers}
              sport={props.sport}
              price_points={props.public_price_points}
              full_position_price_points={createFullPricePointPositionArray()}
            />
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
              waitlist_invitation={props.waitlist_invitation}
              waitlist_entry_id={props.waitlist_entry_id}
            />
          </div>
        );
    }
  };
  const wakeForestRegistrationFlow = () => {
    switch (step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg">
            <CovidInfoForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 5:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 6:
        return (
          <div className={"event-reg payment-form"}>
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
              waitlist_invitation={props.waitlist_invitation}
              waitlist_entry_id={props.waitlist_entry_id}
            />
          </div>
        );
    }
  };

  const customizedDataCollectionFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={waitlist_price_point_ids}
            button_style={buttonStyles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <ExistingAttendeeForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={waitlist_price_point_ids}
            />
          </div>)
      case 2:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <WaitlistPaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={[props.waitlist_entry_price_point]}
              primary_price_point={props.waitlist_entry_price_point}
              primary_camp_price_cents={props.waitlist_entry_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
              waitlist_invitation={props.waitlist_invitation}
              waitlist_entry_id={props.waitlist_entry_id}
            />
          </div>
        );
    }
  };

  return (
    <div className="registration-flow">{renderRegistrationFlow()}</div>
  );
};

export default WaitlistRegistrationSteps;