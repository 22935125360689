import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        serverSide: true,
        paging: true,
        "pageLength": 10,
        deferRender: true,
        lengthMenu: [
          [10, 25, 50, 100],
          ['10 rows', '25 rows', '50 rows', '100 rows'],
        ],
        order: [[7, "desc"]],
        ajax: {
          url: '/admin/camps/inactive_camps_data',
          type: 'POST',
          dataType: 'json',
        },
        columns: [
         { data: 'Event', searchable: true, orderable: true },
         { data: 'Status', searchable: false, orderable: true },
         { data: 'Price Points', searchable: false, orderable: false },
         { data: 'Capacity', searchable: false, orderable: true },
         { data: 'Active', searchable: false, orderable: false },
         { data: 'Inactive', searchable: false, orderable: false },
         { data: 'Total', searchable: false, orderable: false },
         { data: 'Start Date', searchable: false, orderable: true },
         { data: 'End Date', searchable: false, orderable: true },
         { data: 'Actions', orderable: false, searchable: false },
        ],
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: "SEARCH",
        },
      });
    }
  }
}
